import * as React from 'react';
import * as styles from "../styles/page.module.scss";

const Page = (props) => {

    return (
        <div className={`page ${styles.container} ${props.first && styles.first}`}>
            {props.children}
        </div>
    );
}

export default Page;





