import * as React from 'react';
import * as styles from "../styles/button.module.scss";

const Button = (props) => {

    return (
        <>
            {props.link &&
                <a className={styles.button} href={props.link}>
                    {props.children}
                </a>}
            {!props.link &&
                <button className={styles.button} onClick="">
                    {props.children}
                </button>}
        </>
    );
}

export default Button;





