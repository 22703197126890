// extracted by mini-css-extract-plugin
export var calloutItem = "index-module--calloutItem--a0c77";
export var calloutItemDesc = "index-module--calloutItemDesc--8b46c";
export var calloutItemTitle = "index-module--calloutItemTitle--2c962";
export var calloutItems = "index-module--calloutItems--959c0";
export var mobileHide = "index-module--mobileHide--013f1";
export var projectButtonContainer = "index-module--projectButtonContainer--901a4";
export var projectDetailsContainer = "index-module--projectDetailsContainer--11e84";
export var scrollIconContainer = "index-module--scrollIconContainer--f26f8";
export var upDownWiggle = "index-module--upDownWiggle--64ce4";
export var video = "index-module--video--0d8c1";