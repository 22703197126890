// extracted by mini-css-extract-plugin
export var askButton = "assistant-module--askButton--b793a";
export var bubble = "assistant-module--bubble--aa0d1";
export var bubbleContainer = "assistant-module--bubbleContainer--a2346";
export var container = "assistant-module--container--6b052";
export var disclaimer = "assistant-module--disclaimer--20f9a";
export var input = "assistant-module--input--906e4";
export var inputContainer = "assistant-module--inputContainer--685e2";
export var inputInnerContainer = "assistant-module--inputInnerContainer--16c77";
export var opacityPulse = "assistant-module--opacityPulse--df876";
export var responseContainer = "assistant-module--responseContainer--762b6";
export var system = "assistant-module--system--b830b";
export var systemAwaiting = "assistant-module--systemAwaiting--b48a4";
export var user = "assistant-module--user--03655";