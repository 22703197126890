import * as React from 'react';
import * as styles from "../styles/menu.module.scss";

//Assets
import iconMenu from "../images/icon-menu.svg";

const Menu = (props) => {

    //console.log(`PI: `);

    return (
        <div className={styles.container}>
            <div className={`${styles.bar} ${props.pageIndex == 0 && styles.hide}`} style={{ width: `${Math.ceil((props.pageIndex + 1) / Math.max(props.totalPages, 1) * 100)}%` }} />
        </div>
    );
}

export default Menu;





